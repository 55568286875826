@import "utils/__utils";
.menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: var(--main-nav-min-height);
  left: 0;
  z-index: 10;
  padding-top: rem(24);
  width: 100%;
  height: calc(100% - var(--main-nav-min-height));
  color: $color-neutral-0;
  background: $color-primary-dark-pink;
  text-align: center;
  transform: translateX(100%);
  transition: 0.3s ease-out;
  overflow: auto;

  .menu-open & {
    transform: translateX(0%);
  }

  form {
    padding: 0 rem(32);

    & > div {
      position: relative;
      border: rem(1) solid $color-neutral-0;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
    }

    input {
      position: relative;
      z-index: 1;
      padding-right: rem(44);
      color: $color-neutral-0;
      background: $color-primary-dark-pink;
    }

    button {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: rem(24);
      width: rem(19);
      transform: translateY(-50%);
      color: $color-neutral-0;
    }
  }

  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: rem(24) rem(32);

    button {
      padding: rem(32) 0;
      font-size: rem(24);
      text-transform: uppercase;

      @include hover {
        text-decoration: underline;
      }
    }

    a {
      display: block;
      margin-bottom: rem(24);
      text-transform: uppercase;
      text-decoration: none;
      color: $color-neutral-0;

      @include hover {
        text-decoration: underline;
      }
    }

    & > .item {
      border-top: rem(1) solid $color-neutral-0;

      &:last-child {
        border-bottom: rem(1) solid $color-neutral-0;
      }

      .items {
        & > div {
          padding-bottom: rem(32);
        }
      }
    }
  }

  .xfactor {
    border-top: rem(1) solid $color-neutral-0;
    padding: rem(49) 0;

    a {
      color: $color-neutral-0;
      text-decoration: none;
    }
  }
}
