.widget-accordion {
  details {
    border: rem(1) solid $color-neutral-80;
    margin-bottom: rem(8);
  }

  summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(31.5) rem(40);
    list-style: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &::marker {
      display: none;
    }

    &:after {
      content: "";
      display: block;
      width: rem(24);
      height: rem(24);
      background: url(gfx/chevron-down.svg);
    }

    @include hover {
      color: $color-neutral-0;
      background: $color-primary-dark-pink;

      &:after {
        background: url(gfx/chevron-down-white.svg);
      }
    }
  }

  .body-base {
    border-top: rem(1) solid $color-neutral-80;
    padding: rem(40);
  }

  details[open] {
    summary {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
}
