.submenu {
  display: none;

  @include page-break(tablet-lg) {
    display: block;
  }

  a {
    font-size: rem(14);
    text-decoration: none;
    text-transform: uppercase;
    color: $color-neutral-100;

    @include hover(true) {
      color: $color-primary-dark-pink;
    }

    &.active {
      text-decoration: underline;
      color: $color-primary-dark-pink;
    }
  }

  & > nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: rem(40);
    row-gap: rem(20);
    border-top: rem(1) solid $color-neutral-100;
    padding-top: rem(24);
    padding-bottom: rem(24);
  }
}
