@use "sass:math";

.svg {
  display: block;

  & > span {
    display: block;
    position: relative;
    width: 100%;
    height: 0;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.svg-ratio {
  & > span {
    padding-top: calc(var(--svg-height) / var(--svg-width) * 100%);
  }
}

.svg-square {
  & > span {
    @include aspect-ratio(100, 100);
  }
}

.svg-external-link {
  & > span {
    @include aspect-ratio(15, 16);
  }
}

.svg-x-factor {
  & > span {
    @include aspect-ratio(171, 52);
  }
}

.svg-arrow-right {
  & > span {
    @include aspect-ratio(16, 15);
  }
}
