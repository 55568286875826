@use "sass:math";

@keyframes regrow {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@mixin decor-link($size: 2) {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  padding-bottom: rem($size + 2);
  color: $color-neutral-0;
  text-decoration: none;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 100%;
    width: 100%;
    height: rem($size);
    background: var(--theme-color, #{$color-supporting-orange});

    transform: translateX(-50%);
  }

  .svg {
    min-width: math.div(11em, 16);
  }

  @include hover {
    &::after {
      animation: 0.4s regrow;
      animation-timing-function: ease-in-out;
    }
  }
}
