.layout-padding {
  padding-left: rem(16);
  padding-right: rem(16);

  @include page-break("desktop-xl") {
    padding-left: rem(96);
    padding-right: rem(96);
  }
}

.layout-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: rem(16);
  padding-left: rem(16);
  padding-right: rem(16);

  @include page-break("tablet-lg") {
    grid-template-columns: repeat(12, 1fr);
    column-gap: rem(24);
  }

  @include page-break("desktop-xl") {
    padding-left: rem(96);
    padding-right: rem(96);
  }
}

.grid-col-8 {
  grid-column: 1 / span 5;

  @include page-break("tablet-lg") {
    grid-column: 3 / span 8;
  }
}

.grid-col-10 {
  grid-column: 1 / span 5;

  @include page-break("tablet-lg") {
    grid-column: 2 / span 10;
  }
}

.grid-col-12 {
  grid-column: 1 / span 5;

  @include page-break("tablet-lg") {
    grid-column: 1 / span 12;
  }
}
