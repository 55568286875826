.anchor-menu {
  box-sizing: border-box;
  grid-column: 1 / span 5;
  margin-bottom: rem(40);

  & > div {
    & > div {
      box-sizing: border-box;
      border-left: rem(4) solid $color-primary-dark-pink;
      padding: rem(18) rem(22);
    }
  }

  a {
    display: block;
    margin-bottom: rem(18);
    font-size: rem(14);
    text-transform: uppercase;
    text-decoration: none;
    line-height: 136%;
    color: $color-neutral-80;
    transition: none;

    &.active {
      color: $color-primary-dark-pink;
    }

    @include hover {
      text-decoration: underline;
      color: $color-primary-dark-pink;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header-anchor-menu {
  @include page-break {
    display: none;
  }
}
main {
  position: relative;
}

.desktop-anchor-menu {
  display: none;

  @include page-break {
    display: block;
    padding-left: rem(16);
    padding-right: rem(16);
    text-align: right;

    & > div {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: rem(24);

      position: sticky;
      top: rem(96);
      right: 0;

      & > div {
        grid-column: 10 / span 3;
        border-left: none;
        border-right: rem(4) solid $color-primary-dark-pink;
      }
    }
  }

  @include page-break("desktop-xl") {
    padding-left: rem(96);
    padding-right: rem(96);

    & > div {
      & > div {
        grid-column: 11 / span 2;
      }
    }
  }
}
