@import "utils/__utils";

.hamburger {
  position: relative;

  .bar {
    height: rem(2);
    width: 100%;
    display: block;
    margin-bottom: rem(3);
    position: relative;
    background-color: currentColor;
    border-radius: rem(10);
    transition-duration: 0.4s;

    &:nth-of-type(1) {
      transform: translateY(0px) rotate(0);
    }

    &:nth-of-type(2) {
      opacity: 1;
    }

    &:nth-of-type(3) {
      margin-bottom: 0;
      transform: translateY(0px) rotate(0);
    }
  }

  &.open {
    .bar {
      &:nth-of-type(1) {
        transform: translateY(#{rem(5)}) rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(#{rem(-5)}) rotate(-45deg);
      }
    }
  }
}
