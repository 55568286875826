@import "utils/__utils";

.main-nav {
  width: 100%;
  padding-top: rem(41);
  padding-bottom: rem(17);
  min-height: var(--main-nav-min-height);
  align-items: center;

  .home {
    grid-row: 1;
    grid-column: 1 / span 3;

    img {
      width: calc(100% + #{rem(16)});
      max-width: rem(230);
    }
  }

  @include page-break(tablet-lg) {
    align-items: flex-end;
    padding-bottom: rem(24);

    .home {
      img {
        width: 100%;
      }
    }
  }

  .languages {
    grid-row: 1;
    grid-column: 4 / span 2;
    justify-self: center;

    @include page-break(tablet-lg) {
      grid-column: 8 / span 2;
      justify-self: flex-end;
    }

    a {
      color: $color-neutral-100;

      @include hover(true) {
        color: $color-primary-dark-pink;
      }

      &.active {
        color: $color-primary-dark-pink;
      }
    }
  }

  .toggle {
    grid-row: 1;
    grid-column: 5;
    justify-self: flex-end;
    display: block;
    margin: 0;
    padding: 0;
    width: rem(16);
    height: rem(12);
    color: $color-neutral-80;
  }

  & > .items {
    display: flex;
    justify-content: center;
    grid-row: 1;

    grid-column: 4 / span 5;
    justify-self: unset;
    gap: rem(48);

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: $color-neutral-100;

      @include hover(true) {
        color: $color-primary-dark-pink;
      }

      &.active {
        text-decoration: underline;
        color: $color-primary-dark-pink;
      }
    }

    @include page-break(desktop-2xl) {
      grid-column: 5 / span 4;
    }
  }

  & > form {
    grid-row: 1;
    grid-column: 10 / span 3;

    & > div {
      position: relative;
      border: rem(1) solid $color-neutral-100;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
    }

    input {
      position: relative;
      z-index: 1;
      padding-right: rem(44);
    }

    button {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: rem(24);
      width: rem(19);
      transform: translateY(-50%);
      color: $color-neutral-100;
    }
  }
}
