.image {
  & > div,
  & > picture {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;

    @supports (aspect-ratio: 1) {
      height: auto;
      padding-top: 0 !important;
    }

    iframe,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
