body {
  font: #{$root-font-size}/#{$root-line-height} #{$root-font-family};
  color: $color-neutral-80;
}

p {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

.font-xxs {
  font-size: rem(10);
}

.font-xs {
  font-size: rem(12);
}

.font-sm {
  font-size: rem(14);
}

.font-base {
  font-size: rem(16);
}

.font-mb {
  font-size: rem(20);
}

.font-lg {
  font-size: rem(24);
}

.font-xl {
  font-size: rem(32);
}

.font-2xl {
  font-size: rem(40);
}

.font-3xl {
  font-size: rem(48);
}

.font-4xl {
  font-size: rem(56);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/**
 * Display
 * Dispaly text styles are used as larger, higher impact text, such as in a title or section header.
 **/
.dis-lg,
.dis-base,
.dis-sm,
.dis-xs {
  font-weight: 500;
  line-height: 104%;
  letter-spacing: -0.04em;
  text-transform: uppercase;
}

.dis-lg {
  font-size: rem(96);
}

.dis-base {
  font-size: rem(80);
}

.dis-sm {
  font-size: rem(56);
}

.dis-xs {
  font-size: rem(24);
}


/**
 * Heading
 * Heading text styles are used as larger, higher impact text, such as in a title or section header.
 **/
h1,
.hd-4xl,
h2,
.hd-3xl,
h3,
.hd-2xl,
h4,
.hd-xl,
h5,
.hd-lg,
h6,
.hd-md,
.hd-base {
  font-weight: 500;
  line-height: 104%;
}

h1,
.hd-4xl {
  font-size: rem(56);
}

h2,
.hd-3xl {
  font-size: rem(48);
}

h3,
.hd-2xl {
  font-size: rem(40);
}

h4,
.hd-xl {
  font-size: rem(32);
}

h5,
.hd-lg {
  font-size: rem(24);
}

h6,
.hd-md {
  font-size: rem(20);
}

.hd-base {
  font-size: font-range(16, 24);
}


/**
 * Body
 * Body text styles are used in paragraphs and text areas with large amount of text.
 **/
.body-lg,
.body-md,
.body-sm,
.body-xs {
  line-height: 136%;
  font-weight: normal;

  p {
    font-size: 1em;
  }
}

.body-lg {
  font-size: rem(24);
}

.body-md {
  font-size: rem(20);
}

.body-base {
  font-size: rem(16);
  line-height: 148%;
}

.body-sm {
  font-size: font-range(14, 16);
}

.body-xs {
  font-size: rem(12);
}


/**
 * Uppercase
 * Capital text styles are used in paragraphs and text areas if it needs a focuse and also some navigation links.
 **/
.upper-lg,
.upper-md,
.upper-base,
.upper-sm,
.upper-xs {
  text-transform: uppercase;
  font-weight: normal;
  line-height: 136%;
}

.upper-lg {
  font-size: rem(24);
}

.upper-md {
  font-size: rem(20);
}

.upper-base {
  font-size: rem(16);
}

.upper-sm {
  font-size: rem(14);
}

.upper-xs {
  font-size: rem(12);
}


/**
 * Caption
 * Caption text styles are used in buttons, captions and meta text.
 **/
.cap-md,
.cap-base,
.cap-sm,
.cap-xs,
.cap-xxs {
  font-weight: 500;
  line-height: 112%;
}

.cap-md {
  font-size: rem(20);
}

.cap-base {
  font-size: rem(16);
}

.cap-sm {
  font-size: rem(14);
}

.cap-xs {
  font-size: rem(12);
}

.cap-xxs {
  line-height: 100%;
  font-size: rem(10);
}


/**
 * Leading
 * Leading is the line-height of the text styles. Using leading in percentage is better for flexibility and development.
 **/
.leading-none {
  line-height: 100%;
}

.leading-tight {
  line-height: 104%;
}

.leading-snug {
  line-height: 112%;
}

.leading-relaxed {
  line-height: 128%;
}

.leading-loose {
  line-height: 136%;
}

b,
strong {
  font-weight: 500;
}

i,
em {
  font-style: italic;
}

input,
button {
  font-family: $root-font-family;
}

input {
  box-sizing: border-box;
  padding: rem(13) rem(24);
  width: 100%;
  font-size: rem(14);
  color: $color-neutral-80;
}

address {
  font-style: normal;
}
