* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  --main-nav-min-height: #{rem(97)};
  min-height: 100%;

  background-color: $color-neutral-0;
  overflow-x: hidden;

  .menu-open & {
    height: 100%;
    overflow: hidden;
  }

  @media (min-width: $breakpoint-tablet-md) and (min-height: 750px) {
    --main-nav-min-height: #{rem(110)};
  }
}

main {
  position: relative;
  z-index: 2;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
}

input {
  border: none;
  border-radius: 0;
  font-size: rem(14);
}

img {
  display: block;
  width: 100%;
  height: auto;
}

p {
  &:empty {
    display: none;
  }
}

.external-link {
  margin-bottom: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(9);

  .svg {
    min-width: rem(15);

    svg {
      overflow: visible;
    }
  }
}

.to-main-content {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -100%);
  padding: rem(5) rem(10);
  border-radius: rem(0) rem(0) rem(4) rem(4);
  text-decoration: none;
  transition: transform 0.4s ease-in-out;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    transform: translate(-50%, 0%);
  }
}

.default-v-space {
  margin-bottom: rem(40);
}

.languages {
  display: flex;

  a {
    margin-right: rem(4);
    text-decoration: none;
    white-space: nowrap;

    &:after {
      content: "|";
      padding-left: rem(4);
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
        content: "";
      }
    }

    &.active {
      text-decoration: underline;
    }
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
