// Fonts -----------------------------------------------------------------------
$root-font-family: Ubuntu, Arial, Helvetica, sans-serif;
$root-font-size: 100%;
$root-line-height: normal;
$root-font-weight: 400;

// Colors ----------------------------------------------------------------------

$color-primary-dark-pink: #bc1047;
$color-primary-pink: #cc386c;
$color-primary-light-pink: #dd6093;
$color-primary-light-40: #F4B4C2;
$color-primary-light-20: #f6dee3;


$color-secondary-beige: #fbefe1;
$color-secondary-blue: #dbedff;
$color-secondary-gray: #efefef;

$color-neutral-100: #000;
$color-neutral-80: #202125;
$color-neutral-0: #fff;

$color-supporting-dark-green: #292A3C;
$color-supporting-green: #214259;
$color-supporting-orange: #FF7242;
$color-supporting-blue: #00A3CE;

$color-base: $color-neutral-100;

/**
 * Meassurements
 *
 * use mixin to use media queries:
 *
 * @include page-break('mobile-md') {
 *    color: blue;
 * }
 *
 **/

$breakpoint-mobile-md: rem(375);
$breakpoint-mobile-lg: rem(414);

$breakpoint-tablet-sm: rem(640);
$breakpoint-tablet-md: rem(768);
$breakpoint-tablet-lg: rem(1024);

$breakpoint-desktop-xl: rem(1280);
$breakpoint-desktop-2xl: rem(1440);
$breakpoint-desktop-3xl: rem(1920);

// // Easing effects
// $ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
// $ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
// $ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

// $ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
// $ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
// $ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

// $ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
// $ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
// $ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// $ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
// $ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
// $ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

// $ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
// $ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
// $ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// $ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
// $ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
// $ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

// $ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
// $ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
// $ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// $ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
// $ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
// $ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// $ease-in-out-fast: cubic-bezier(1, 0, 0, 1);
