.widget-image {
  .image {
    margin-bottom: rem(16);

    & > div {
      @include aspect-ratio(342, 236);
    }
  }

  @include page-break {
    .image {
      margin-bottom: rem(32);
    }
  }
}
