.article-top-image {
  border-top: rem(1) solid $color-neutral-100;
  border-bottom: rem(1) solid $color-neutral-100;

  @include page-break(tablet-lg) {
    margin-bottom: rem(96);
  }

  picture {
    @include aspect-ratio(375, 242);

    @media (orientation: landscape), (min-width: $breakpoint-tablet-lg) {
      @include aspect-ratio(1512, 560);
    }
  }
}
