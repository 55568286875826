.article-search-form {
  margin-bottom: rem(40);

  label,
  input {
    grid-row: 1;
    grid-column: 1 / span 5;
    background: $color-neutral-0;
  }

  input {
    border: rem(1) solid $color-neutral-80;
    padding-right: rem(40);
  }

  button {
    grid-row: 1;
    grid-column: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: rem(24);
    justify-self: flex-end;

    .svg {
      min-width: rem(19);
      width: rem(19);
    }
  }

  @include page-break {
    margin-bottom: rem(96);

    label,
    input {
      grid-column: 4 / span 6;
    }

    button {
      grid-column: 9;
    }
  }

  @include page-break(desktop-xl) {
    label,
    input {
      grid-column: 5 / span 4;
    }

    button {
      grid-column: 8;
    }
  }
}
