@use "sass:math";

.homepage {
  position: relative;
  height: 100%;
  overflow: hidden;

  main {
    position: relative;
    height: calc(100% - var(--main-nav-min-height));
    gap: rem(1);
    display: flex;
    flex-direction: column;
    background: $color-neutral-80;
  }

  main-nav-component {
    border-bottom: rem(1) solid $color-neutral-80;
  }

  .audience {
    --nav-height: #{rem(55)};
    --nav-padding: #{rem(30)};

    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    text-align: center;
    flex: 1 1 auto;
    transition: all 0.4s ease-in-out;
    color: $color-neutral-0;
    background: $color-primary-dark-pink;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba($color-primary-dark-pink, 0.4);
      transition: all 0.4s ease-in-out;
    }

    h2 {
      font-size: rem(24);
      font-size: clamp(#{rem(24)}, 6vw, #{rem(56)});
      line-height: 120%;
      text-transform: uppercase;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      z-index: 0;
    }

    &:last-child {
      img {
        top: auto;
        bottom: 0;
      }
    }

    nav {
      position: relative;
      z-index: 2;
      padding: rem(32) rem(16);
      padding: 0 rem(16);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      transition: all 0.4s ease-in-out;
    }

    .entries {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.4s ease-in-out;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: var(--nav-padding);
      }

      a {
        margin-bottom: rem(24);
        font-size: rem(16);
        text-transform: uppercase;
        text-decoration: none;
        color: $color-neutral-0;

        @include hover {
          text-decoration: underline;
        }
      }
    }

    &:hover {
      &:before {
        background: rgba($color-primary-dark-pink, 0.8);
      }

      .entries {
        opacity: 1;
        max-height: calc((var(--items) * var(--nav-height)) + var(--nav-padding));
      }
    }

    @media (min-height: 800px) {
      --nav-padding: #{rem(60)};
      --nav-height: #{rem(46)};

      nav {
        padding: rem(64) rem(32);
      }

      &:hover {
        .entries {
          overflow: hidden;
        }
      }
    }
  }

  @media (orientation: landscape) {
    main {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

      .audience {
        h2 {
          font-size: clamp(#{rem(24)}, 3.5vw, #{rem(48)});
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover {
          &:before {
            background: rgba($color-primary-dark-pink, 1);
          }
        }
      }
    }
  }

  @media (orientation: landscape) and (min-height: 800px) and (min-width: $breakpoint-tablet-lg) {
    main {
      .audience {
        --nav-height: #{rem(54)};
        --nav-padding: #{rem(64)};
        justify-content: center;

        h2 {
          margin: 0 auto;
          max-width: rem(600);
          font-size: clamp(#{rem(24)}, 3.5vw, #{rem(56)});
        }

        nav {
          padding: clamp(#{rem(64)}, 6.66vw, #{rem(200)});
        }

        .entries {
          a {
            margin-bottom: rem(32);
          }

          .x-factor {
            position: absolute;
            left: 0;
            bottom: 0;
            border-top: rem(1) solid $color-neutral-0;
            padding: rem(49);
            width: 100%;

            a {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
