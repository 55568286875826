@use "sass:math";

@mixin hover($focus: false, $outline: false) {
  @if $focus {
    &:focus {
      @if $outline {
        outline: none;
      }

      @content;
    }
  }
  .no-touch & {
    &:hover {
      @content;
    }
  }
}

@mixin page-break($breakpoint: "tablet-lg") {
  @if $breakpoint == "mobile-md" {
    @media screen and (min-width: $breakpoint-mobile-md) {
      @content;
    }
  } @else if $breakpoint == "mobile-lg" {
    @media screen and (min-width: $breakpoint-mobile-lg) {
      @content;
    }
  } @else if $breakpoint == "tablet-sm" {
    @media screen and (min-width: $breakpoint-tablet-sm) {
      @content;
    }
  } @else if $breakpoint == "tablet-md" {
    @media screen and (min-width: $breakpoint-tablet-md) {
      @content;
    }
  } @else if $breakpoint == "tablet-lg" {
    @media screen and (min-width: $breakpoint-tablet-lg) {
      @content;
    }
  } @else if $breakpoint == "desktop-xl" {
    @media screen and (min-width: $breakpoint-desktop-xl) {
      @content;
    }
  } @else if $breakpoint == "desktop-2xl" {
    @media screen and (min-width: $breakpoint-desktop-2xl) {
      @content;
    }
  } @else if $breakpoint == "desktop-3xl" {
    @media screen and (min-width: $breakpoint-desktop-3xl) {
      @content;
    }
  }
}

@mixin aspect-ratio($width, $height) {
  padding-top: calc(var(--aspect-ratio-height, $height) / var(--aspect-ratio-width, $width) * 100%);

  @supports (aspect-ratio: 1) {
    aspect-ratio: var(--aspect-ratio-width, $width) / var(--aspect-ratio-height, $height);
    padding-top: 0;
    height: auto;
  }
}
