.article-entry {
  main {
    display: grid;
    grid-template-columns: 1fr;
  }

  .submenu {
    grid-column: 1;
    grid-row: 1;
  }

  header {
    grid-column: 1;
    grid-row: 2;
  }

  .content {
    grid-column: 1;
    grid-row: 3;
    padding-bottom: rem(40);
  }

  .desktop-anchor-menu {
    grid-column: 1;
    grid-row: 3 / span 3;
  }

  .body-base {
    position: relative;
    z-index: 2;

    h3 {
      margin-bottom: 1rem;
      font-size: 2rem;
      color: $color-primary-dark-pink;

      @include page-break(tablet-lg) {
        margin-bottom: rem(32);
        font-size: rem(40);
      }
    }

    p + h2,
    p + h3 {
      padding-top: 1em;
    }
  }

  @include page-break(tablet-lg) {
    .content {
      padding-bottom: rem(96);
    }
  }
}
