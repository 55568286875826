@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("~fonts/ubuntu/ubuntu-v20-latin-regular.woff2") format("woff2"),
    url("~fonts/ubuntu/ubuntu-v20-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("~fonts/ubuntu/ubuntu-v20-latin-italic.woff2") format("woff2"),
    url("~fonts/ubuntu/ubuntu-v20-latin-italic.woff") format("woff");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("~fonts/ubuntu/ubuntu-v20-latin-500.woff2") format("woff2"),
    url("~fonts/ubuntu/ubuntu-v20-latin-500.woff") format("woff");
}

@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("~fonts/ubuntu/ubuntu-v20-latin-500italic.woff2") format("woff2"),
    url("~fonts/ubuntu/ubuntu-v20-latin-500italic.woff") format("woff");
}
