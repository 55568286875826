@use "sass:math";

.widget-exit-link {
  border-top: rem(1) solid $color-neutral-80;
  border-bottom: rem(1) solid $color-neutral-80;
  padding-top: rem(40);
  padding-bottom: rem(40);

  .body-lg {
    margin-bottom: rem(17);
  }

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color-primary-dark-pink;
    text-decoration: none;

    @include hover {
      .text {
        text-decoration: underline;
      }
    }
  }

  @include page-break(tablet-lg) {
    padding-top: rem(48);
    padding-bottom: rem(48);

    .body-lg {
      margin-bottom: rem(33);
    }
  }
}
