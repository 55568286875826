.widget-list {
  .list-container {
    margin-bottom: rem(16);
    border: 1px solid $color-neutral-80;
    border-right-width: rem(4);
    border-bottom-width: rem(4);
    background: $color-primary-light-20;

    &:nth-child(even) {
      background: $color-primary-light-40;
    }

    li {
      &::marker {
        color: currentColor;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-bottom: 0;
    }

    .list-header {
      padding: rem(16);
      background: $color-primary-light-pink;
    }

    .list {
      padding: rem(24) rem(16);
    }
  }

  @include page-break(desktop-xl) {
    .lists {
      display: flex;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      gap: rem(36)
    }

    .list-container {
      margin-bottom: 0;
    }
  }
}
