.error-page {
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }

  article {
    text-align: center;
  }

  h1 {
    margin-bottom: 0.6em;
  }

  .body {
    margin-bottom: rem(32);
  }
}
