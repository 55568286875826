.widget-text {
  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 1rem;
    color: $color-primary-dark-pink;
  }

  .intro {
    margin-bottom: rem(16);
  }

  @include page-break(tablet-lg) {
    h2 {
      margin-bottom: rem(32);
      font-size: rem(40);
    }

    .intro {
      margin-bottom: rem(32);
    }
  }
}
