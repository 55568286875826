a {
  color: #84003e;

  transition: color 200ms ease-out;

  &:hover {
    color: #bc1047;
  }
}

ul,
ol {
  margin-bottom: rem(32);
  margin-left: rem(18);

  li {
    margin-bottom: 1em;

    &::marker {
      color: $color-primary-dark-pink;
    }

    a {
      color: $color-primary-dark-pink;

      &:hover {
        color: $color-supporting-blue;
      }
    }
  }
}

ul {
  margin-left: rem(30);
  list-style-type: "—   ";
}

ol {
  margin-left: rem(30);

  li {
    counter-increment: ol-counter;

    &::marker {
      content: counter(ol-counter) ".   ";
    }
  }
}

.plus-list {
  margin-left: rem(30);
  list-style-type: "+  ";

  li {
    &::marker {
      font-size: 1.4em;
    }
  }
}

table {
  margin-bottom: rem(16);
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @include page-break {
    margin-bottom: rem(32);
  }
}

td,
th {
  display: none;
  border: rem(1) solid $color-secondary-gray;
  padding: rem(16);
  text-align: left;
  vertical-align: top;
  line-height: 2em;

  &:first-child {
    display: table-cell;
  }

  &:last-child {
    display: table-cell;
  }

  @include page-break(tablet-md) {
    display: table-cell;
  }
}

th {
  font-weight: 500;
  font-size: rem(20);
  line-height: 136%;
}

td {
  font-size: rem(16);
}

thead {
  th,
  td {
    background: $color-secondary-gray;
  }
}

.button {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  text-align: left;
  border-radius: rem(42);
  padding: rem(16) rem(32);
  font-size: rem(16);
  line-height: 112%;
  text-decoration: none;
  color: $color-neutral-0;
  background: $color-primary-dark-pink;
  transition: 0.2s ease-in-out;

  .svg-external-link {
    min-width: rem(16);
  }

  @include hover {
    color: $color-neutral-0;
    background: $color-primary-pink;
  }
}

.to-main-content {
  color: $color-neutral-0;
  background: $color-primary-pink;
}

main-nav-component {
  display: block;
  width: 100%;
  min-height: var(--main-nav-min-height);
}
