@use "sass:math";

.footer-main {
  padding-top: rem(48);
  padding-bottom: rem(96);
  color: $color-neutral-0;
  row-gap: 0;
  background: $color-neutral-100;

  & > * {
    grid-column: 1 / span 5;
  }

  a {
    color: currentColor;
    text-decoration: none;

    @include hover(true) {
      text-decoration: underline;
    }
  }

  dl {
    display: block;
  }

  .children {
    display: none;
  }

  .item {
    margin-bottom: rem(24);
  }

  .x-factor {
    margin-bottom: rem(40);
    padding-top: rem(17);
  }

  address {
    margin-bottom: rem(40);
    border-top: rem(1) solid $color-neutral-0;
    border-bottom: rem(1) solid $color-neutral-0;
    padding-top: rem(40);
    padding-bottom: rem(40);

    dt {
      margin-bottom: math.div(4em, 14);
    }

    dl {
      & > div {
        margin-bottom: rem(24);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .partners {
    margin-bottom: rem(40);
    padding-bottom: rem(40);
    border-bottom: rem(1) solid $color-neutral-0;

    h2 {
      margin-bottom: rem(24);
    }

    .logo {
      width: 100%;
      max-width: rem(184);
    }

    .logo-ntnu {
      max-width: rem(178);
    }

    dt {
      margin-bottom: rem(16);
    }

    dl {
      width: 100%;

      & > div {
        width: 100%;
        margin-bottom: rem(40);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .homepage {
    grid-column: 1 / span 4;
    margin-bottom: rem(40);
    width: 100%;
    max-width: rem(384);

    a {
      display: block;
    }
  }

  @include page-break(tablet-lg) {
    .children {
      display: block;
    }

    .item {
      margin-bottom: 0;

      a {
        display: inline-block;
        margin-bottom: rem(24);
      }

      & > a {
        margin-bottom: rem(48);
      }
    }

    .item {
      grid-row: 1;
    }

    .item-1 {
      grid-column: 1 / span 4;
    }

    .item-2 {
      grid-row: 1 / span 2;
      grid-column: 5 / span 4;
    }

    address {
      grid-row: 1 / span 2;
      grid-column: 9 / span 4;
      border: none;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;

      dl {
        & > div {
          margin-bottom: rem(48);
        }
      }
    }

    .x-factor {
      grid-row: 2;
      grid-column: 1 / span 4;
      padding-top: 0;
      margin-bottom: 0;
    }

    .partners {
      grid-row: 3;
      grid-column: 1 / span 12;
      margin-top: rem(96);
      margin-bottom: rem(96);
      border-top: rem(1) solid $color-neutral-0;
      padding-top: rem(96);
      padding-bottom: rem(96);

      dl {
        display: flex;
        gap: rem(124);

        & > div {
          // max-width: rem(265);
        }
      }
    }

    .homepage {
      grid-row: 4;
      grid-column: 9 / span 4;
      margin-bottom: 0;
    }

    .privacy {
      grid-row: 4;
      grid-column: 1 / span 4;
    }
  }

  @include page-break(desktop-xl) {
    padding-top: rem(96);

    .item-1 {
      grid-column: 1 / span 3;
    }

    .item-2 {
      grid-column: 4 / span 4;
    }

    address {
      grid-column: 10 / span 3;
    }

    .homepage,
    .privacy {
      align-self: flex-end;
    }

    .homepage {
      grid-column: 10 / span 3;
      justify-self: flex-end;
    }
  }
}
