.widget-entry {
  display: block;
  margin-bottom: rem(24);
  border: rem(1) solid $color-neutral-80;
  text-decoration: none;
  color: $color-neutral-80;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    transition: 0.4s ease-in-out;
  }

  .image {
    margin-bottom: rem(24);

    & > div {
      @include aspect-ratio(343, 219);
    }
  }

  h3 {
    margin-bottom: rem(12);
  }

  .text {
    padding: rem(0) rem(16) rem(24);
  }

  @include hover {
    color: $color-neutral-80;

    img {
      transform: scale(1.02);
    }

    h3 {
      text-decoration: underline;
    }
  }
}

.entry-grid {
  @include page-break(tablet-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: rem(24);
    row-gap: rem(32);

    .widget-entry {
      margin-bottom: 0;
    }
  }

  @include page-break(desktop-xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
