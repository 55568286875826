.menu {
  .item {
    .items {
      overflow: hidden;
      transition: 0.4s ease-in-out;
    }
  }
}


