.article-list {
  h1 {
    text-align: center;
  }

  header {
    margin-bottom: rem(40);

    @include page-break {
      margin-bottom: rem(48);
    }
  }

  main {
    margin-bottom: rem(80);

    @include page-break {
      margin-bottom: rem(150);
    }
  }
}
