.search-result {
  main-nav-component {
    border-bottom: rem(1) solid $color-neutral-80;
    margin-bottom: rem(40);
  }

  main {
    padding-bottom: rem(76);
  }

  h1 {
    margin-bottom: rem(32);
  }

  @include page-break {
    main-nav-component {
      margin-bottom: rem(96);
    }

    h1 {
      margin-bottom: rem(48);
    }

    .article-search-form {
      label,
      input {
        grid-column: 2 / span 4;
      }

      button {
        grid-column: 5;
      }
    }
  }

  @include page-break(desktop-xl) {
    .article-search-form {
      label,
      input {
        grid-column: 2 / span 3;
      }

      button {
        grid-column: 4;
      }
    }
  }
}
