.widget-relations {
  @include page-break {
    .entries {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: rem(24);
    }

    .widget-entry {
      margin-bottom: 0;
    }
  }
}
