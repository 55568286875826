.article-intro {
  margin-bottom: rem(16);

  &:last-child{
    margin-bottom: 0;
  }

  @include page-break(tablet-lg) {
    padding-top: 2em;
    margin-bottom: rem(48);
    font-size: rem(24);
  }
}
