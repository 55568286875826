.widget-info {
  .box {
    margin-bottom: rem(11);
    border: rem(1) solid $color-neutral-80;
    border-right-width: rem(4);
    border-bottom-width: rem(4);

    @include page-break {
      margin-bottom: rem(32);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .hd-base {
      padding: rem(16);
      background: $color-secondary-gray;
    }

    .body-base {
      padding: rem(16);
    }
  }
}
